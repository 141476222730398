<template>
  <div>
    <InviteFriendsModal />
    <div class="left-menu-wrapper">
      <ul class="menu">
        <li class="item">
          <router-link class="item-link" exact to="/tasks">
            <div class="img-wraper"><img :src="imageTask" alt="task" />
            </div>
            <span class="tooltiptext">Tasks</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/">
            <div class="img-wraper"><img :src="imageHome" alt="opinion" /></div>
            <span class="tooltiptext">Opinions</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/friends">
            <div class="img-wrapper"><img :src="imageFriends" alt="friends" /></div>
            <span class="tooltiptext">Friends</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/pros">
            <div class="img-wrapper"><img :src="imageSpecialist" alt="" /></div>
            <span class="tooltiptext">Specialists</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/order-history">
            <div class="img-wrapper">
              <img :src="imageOrderHistory" alt="" />
            </div>
            <span class="tooltiptext">Orders</span>
          </router-link>
        </li>
        <li class="item">
          <a class="item-link feedback-btn" data-target="#feedBackModal" data-toggle="modal">
            <div class="img-wrapper">
              <img src="../../assets/Svg/like.svg" alt="" />
            </div>
            <span class="tooltiptext">Feedback</span>
          </a>
        </li>

        <li class="item">
          <div class="item-link">
          <div :style="{ display: chatbotContainerDisplay }" id="chatbot-container">
            <!-- Chatbot interface -->
            <div id="chatbot-interface">
              <div id="chatbot-header">
                <p>
                  <img class="header-image mr-2" src="../../assets/Svg/logo.svg" />
                  <span class="">AI Help</span>
                </p>
                <img @click="closeAI" src="../../assets/Svg/close.svg" alt="close"  class="close-ai"/>
              </div>
              <div id="chatbot-chat">
                <!-- Display chat messages in reverse order -->
                <div v-for="(message, index) in chatMessages.slice().reverse()" :key="index" :class="{
                  'chatbot-messages': true,
                  'chatbot-sent-messages': message.type === 'sent',
                  'chatbot-received-messages': message.type === 'received',
                }">
                  <p>{{ message.text }}</p>
                </div>
              </div>

              <div id="chatbot-footer">
                <div id="chatbot-input-container">
                  <input type="text" v-model="inputText" id="chatbot-input" name="chatbot-input"
                    placeholder="Type a command here..." @keydown.enter.prevent="sendOnEnter" ref="chatInput" />
                </div>
                <div id="chatbot-new-message-send-button" @click="sendInputTo">
                  <div>
                    <div class="shop-products-img-loader" v-if="showLoader">
                      <div class="lds-dual-ring-media"></div>
                    </div>
                    <img class="closeIcon" src="../../assets/images/send.png" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="chatbot-open-container" v-show="showChatbotContainer">
            <div id="open-chat-button" @click="toggleChat"><img class="chatIcon"
                src="../../assets/Svg/Ai-help.svg">
              <span class="tooltiptext">AI Help</span>
              </div>
          </div>
        </div>
        </li>
        <!-- <li class="item">
          <router-link class="item-link" exact to="/box-scores">
            <img :src="boxScore" alt="" />
            <span class="tooltiptext">Box Scores</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/live-stream">
            <img :src="liveStream" alt="" />
            <span class="tooltiptext">Live Stream</span>
          </router-link>
        </li> -->
        <!-- <li class="item text-center">
          <router-link class="item-link" exact to="/score-game">
            <img :src="boxScore" alt="" />
            <span class="tooltiptext">Single Box Score</span>
          </router-link>
        </li> -->
      </ul>
      <ul class="menu mb-2 d-flex flex-column align-items-center">
        <!-- <div class="item-link">
          <div :style="{ display: chatbotContainerDisplay }" id="chatbot-container">
            <div id="chatbot-interface">
              <div id="chatbot-header">
                <p>
                  <img class="header-image mr-2" src="../../assets/Svg/logo.svg" />
                  <span>AI Help</span>
                </p>
              </div>
              <div id="chatbot-chat">
                <div v-for="(message, index) in chatMessages.slice().reverse()" :key="index" :class="{
                  'chatbot-messages': true,
                  'chatbot-sent-messages': message.type === 'sent',
                  'chatbot-received-messages': message.type === 'received',
                }">
                  <p>{{ message.text }}</p>
                </div>
              </div>

              <div id="chatbot-footer">
                <div id="chatbot-input-container">
                  <input type="text" v-model="inputText" id="chatbot-input" name="chatbot-input"
                    placeholder="Type a command here..." @keydown.enter.prevent="sendOnEnter" ref="chatInput" />
                </div>
                <div id="chatbot-new-message-send-button" @click="sendInputTo">
                  <div>
                    <div class="shop-products-img-loader" v-if="showLoader">
                      <div class="lds-dual-ring-media"></div>
                    </div>
                    <img class="closeIcon" src="../../assets/images/send.png" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="chatbot-open-container" v-show="showChatbotContainer">
            <div id="open-chat-button" @click="toggleChat" v-click-outside="closeAI"><img class="chatIcon"
                src="../../assets/Svg/AI Help.svg"></div>
          </div>
        </div> -->
        <li class="item">
          <div class="img-wrapper cursor-pointer" @click="callModal()">
            <img src="../../assets/Svg/invite-btn.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InviteFriendsModal from "./InviteFriendsModal.vue";
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
  name: "LeftMenu",
  data() {
    return {
      currentTabUrl: "",
      imageHome: require("../../assets/Svg/firstline.svg"),
      homeBlue: require("../../assets/Svg/firstline-blue.svg"),
      homeGrey: require("../../assets/Svg/firstline.svg"),
      imageTask: require("../../assets/Svg/document-text.svg"),
      taskBlue: require("../../assets/Svg/document-text-blue.svg"),
      taskGrey: require("../../assets/Svg/document-text.svg"),
      imageSpecialist: require("../../assets/Svg/award.svg"),
      imageOrderHistory: require("../../assets/Svg/receipt-item.svg"),
      specialistsGrey: require("../../assets/Svg/award.svg"),
      specialistsBlue: require("../../assets/Svg/award-blue.svg"),
      orderHistoryGrey: require("../../assets/Svg/receipt-item.svg"),
      orderHistoryBlue: require("../../assets/Svg/receipt-item-blue.svg"),
      friendsBlue: require("../../assets/Svg/friends-blue.svg"),
      friendsGrey: require("../../assets/Svg/friends.svg"),
      imageFriends: require("../../assets/Svg/friends.svg"),
      eventsBlue: require("../../assets/calender.png"),
      eventsGrey: require("../../assets/calender-grey.png"),
      boxScore: require("../../assets/images/box-score-grey.png"),
      boxBlue: require("../../assets/images/boxscore.png"),
      boxGrey: require("../../assets/images/box-score-grey.png"),
      liveStreamBlue: require("../../assets/images/wifi-square-active.png"),
      liveStreamGrey: require("../../assets/images/wifi-square.png"),
      liveStream: require("../../assets/images/wifi-square.png"),
      chatbotContainerDisplay: "none",
      isOpen: false,
      inputText: "",
      chatMessages: [], // To store chat messages
      showLoader: 0,
      showChatbotContainer: true, // Control the display of the chatbot container
    };
  },
  computed: {
    ...mapGetters("auth", ["subscribedInfo"]),
  },
  components: {
    InviteFriendsModal,
  },
  methods: {
    sendInputTo() {
      const newText = this.inputText.trim();

      if (newText !== "") {
        this.inputText = "";
        this.showLoader = 1;
        // Create an object to send to the server
        const messageData = {
          message: newText,
        };

        // Make an HTTP POST request to your Node.js server
        axios
          .post(process.env.VUE_APP_API_URL + "chatbot/sendMessage", messageData)
          .then((response) => {
            if (response.data.chatbotResponse.status === 200) {
              const responseData = response.data;
              this.showLoader = 0;

              // Add user's input to chatMessages
              this.addMessage("sent", newText);
              // this.addMessage("received", responseData.chatbotResponse.chatbotResponse);
              const responseLines = responseData.chatbotResponse.chatbotResponse.split("\n");

              // Iterate through response lines and add them to chatMessages
              responseLines.forEach((line) => {
                this.addMessage("received", line);
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              // Prepare the unauthorized message split into lines
              const unauthorizedMessage = "Unauthorized \n Please log in to continue.";
              const unauthorizedLines = unauthorizedMessage.split("\n");

              // Handle errors with HTTP response status codes
              if (error.response.status === 401) {
                this.addMessage("sent", newText);
                unauthorizedLines.forEach((line) => {
                  this.addMessage("received", line);
                });
                this.showLoader = 0;
              } else if (error.response.status === 404) {
                this.addMessage("sent", newText);
                this.addMessage("received", error.response.data.error);
                this.showLoader = 0;
              } else if (error.response.status === 500) {
                this.addMessage("sent", newText);
                this.addMessage("received", error.response.data.error);
                this.showLoader = 0;
              } else {
                this.addMessage("sent", newText);
                this.addMessage("received", error.response.statusText);
                this.showLoader = 0;
              }
            } else {
              this.addMessage("sent", newText);
              this.addMessage("received", "Network error or server unreachable.");
              this.showLoader = 0;
            }
          });
      }
    },
    sendOnEnter() {
      if (this.inputText.trim() !== "") {
        this.sendInputTo();
        this.$refs.chatInput.focus(); // Focus the input field again after sending
      }
    },
    toggleChat() {
      this.isOpen = true;
      this.chatbotContainerDisplay = "block";
    },
    closeAI() {
      this.isOpen = false;
      this.chatbotContainerDisplay = "none"
    },
    addMessage(type, text) {
      this.chatMessages.push({ type, text });
    },
    callModal: function () {
      this.$root.$emit("openInviteFriendsModal");
      // document.querySelector("body").style.overflow = "hidden";
    },
    imageSwap() {
      this.currentTabUrl = localStorage.getItem("currentUrl");

      if (this.currentTabUrl == "/") {
        this.imageTeam = this.teamGrey;
        this.imageTask = this.taskGrey;
        this.imageHome = this.homeBlue;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      }
      else if (this.currentTabUrl == "/tasks") {
        this.imageTeam = this.teamGrey;
        this.imageTask = this.taskBlue;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsBlue;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      }
      else if (this.currentTabUrl == "/pros") {
        this.imageTeam = this.teamGrey;
        this.imageTask = this.taskGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsBlue;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/order-history") {
        this.imageTeam = this.teamGrey;
        this.imageTask = this.taskGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryBlue;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/friends") {
        this.imageTeam = this.teamGrey;
        this.imageTask = this.taskGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsBlue;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/box-scores") {
        this.boxScore = this.boxBlue;
        this.imageTask = this.taskGrey;
        this.imageShop = this.shopGrey;
        this.imageHome = this.homeGrey;
        this.imageTeam = this.teamGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/live-stream") {
        this.boxScore = this.boxGrey;
        this.imageTask = this.taskGrey;
        this.imageShop = this.shopGrey;
        this.imageHome = this.homeGrey;
        this.imageTeam = this.teamGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamBlue;
      }
    },
  },
  mounted() {
    this.imageSwap();
    this.$root.$on("showChatbot", (data) => {
      if (data === false) {
        this.isOpen = false;
        this.chatbotContainerDisplay = "none";
        this.showChatbotContainer = false;
      } else if (data === true){
        this.showChatbotContainer = true;
      }
    });
  },
};
</script>

<style scoped>

.shop-products-img-loader {
    position: sticky;
    left: 0 !important;
    z-index: 99;
}

#chatbot-container {
  z-index: 1000;
  color: #2c2325;
  position: absolute;
  bottom: 10px;
  left: 70px;
  width: 400px;
  height: 40vh;
  animation: slide-right 0.25s ease-in-out forwards; /* Apply the animation */
}

@keyframes slide-right {
  0% {
    left: 0px; /* Starting position */
  }
  100% {
    right: 0; /* Ending position */
  }
}
#chatbot-interface {
  height: 40vh;
  width: 100%;
  background-color: #e2f5fe;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  -webkit-box-shadow: 0px 2px 4px 0px #00000040;
  -moz-box-shadow: 0px 2px 4px 0px #00000040;
  -ms-box-shadow: 0px 2px 4px 0px #00000040;
  -o-box-shadow: 0px 2px 4px 0px #00000040;
}

#chatbot-header {
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #188ef5;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.header-image{
  width: 35px;
  filter: brightness(15);
}

#chatbot-header p span {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}

#chatbot-header > img {
  cursor: pointer;
  filter: brightness(15);
  margin: auto 0;
  width: 35px;
  height: 35px;
}

#chatbot-chat {
  height: calc(100% - 22px - 6rem);
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  padding: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.chatbot-messages {
  padding: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: fit-content;
}

.chatbot-received-messages {
  border-top-left-radius: 0rem;
  background: white;
  color: black
}

.chatbot-sent-messages {
  background: #407FFF;
  color: white;
  border-top-right-radius: 0rem;
  margin-left: auto;
  margin-right: 0;
}

#chatbot-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

#chatbot-input-container {
  width: 85%;
}

#chatbot-input {
  width: 100%;
  padding: 5px;
  color: black;
  border: 0.1rem solid #188ef5!important;
  border-radius: 0.5rem;
  margin-left: 5px;
}

#chatbot-input:focus {
  outline-offset: 0px !important;
  outline: none !important;
  border: 0.1rem solid #8a6e2f;
}

#chatbot-new-message-send-button {
  cursor: pointer;
  margin-right: 5px;
  padding-bottom: 1px;
}

#send-icon {
  color: white;
}

#chatbot-open-container {
  /* height: 56px;
  width: 56px; */
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
</style>